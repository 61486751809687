import { IPostVideoProps } from "../../../components/BrandedCommunity/PostVideo/PostVideo.types";

export const evaluateHidePostVideo = ({
  showVideo,
  videoUrl,
}: {
  showVideo: IPostVideoProps["showVideo"];
  videoUrl: IPostVideoProps["videoUrl"];
}) => {
  if (!showVideo || !videoUrl) return true;
};
