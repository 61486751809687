import { IPostImageProps } from "../../../components/BrandedCommunity/PostImage/PostImage.types";

export const evaluateHidePostImage = ({
  showImg,
  imgSrc,
}: {
  showImg: IPostImageProps["showImg"];
  imgSrc: IPostImageProps["imgSrc"];
}) => {
  if (!showImg || !imgSrc) return true;
};
