import { useState } from "react";

/**
 * A wrapper over useState for boolean only states which can be set on or off.
 * ```
 * const [isPopupOpen, showPopup, hidePopup, toggleIsPopupOpen] = useToggleState(false);
 * ```
 */
export const useToggleState = (
  initialState?: boolean,
): [
  state: boolean,
  setTrue: () => void,
  setFalse: () => void,
  toggle: () => void,
] => {
  const [state, setState] = useState(initialState ?? false);
  const setTrue = () => setState(true);
  const setFalse = () => setState(false);
  const toggle = () => setState((prevState) => !prevState);
  return [state, setTrue, setFalse, toggle];
};
