import {
  DEFAULT_POST_TYPE_ID,
  POST_TYPE_IDS_ENUM,
} from "../../../constants/BrandedCommunity/Post";
import {
  IPostDataInEditingFlow,
  IPostDataInFeed,
} from "../../../components/BrandedCommunity/Post/Post.types";
import {
  IUseDocInEditPostModalReturn,
  useDocInEditPostModal,
} from "./useDocInEditPostModal";
import {
  IUseImageInEditPostModalReturn,
  useImageInEditPostModal,
} from "./useImageInEditPostModal";
import {
  IUseOfferingInEditPostModalProps,
  IUseOfferingInEditPostModalReturn,
  useOfferingInEditPostModal,
} from "./useOfferingInEditPostModal";
import {
  IUseVideoInEditPostModalReturn,
  useVideoInEditPostModal,
} from "./useVideoInEditPostModal";
import { useEffect, useRef, useState } from "react";
import { IEditPostModalProps } from "../../../components/BrandedCommunity/EditPostModal/EditPostModal.types";
import { IUseMediaInEditPostModalProps } from "./useMediaInEditPostModal.types";

const POST_UPDATE_PAYLOAD_DEFAULT_VALUE: IPostDataInEditingFlow = {
  post_heading: "",
  post_body: "",
  post_type: DEFAULT_POST_TYPE_ID,
};

export interface IUseEditPostModalProps {
  isOpen: IEditPostModalProps["isOpen"];
  onClose: () => void;
  postData?: IPostDataInFeed;
  disablePostTypeChange?: boolean;
  disablePostTypeDataEdit?: boolean;
  triggerPostTypeId?: number;
  onSaveButtonClick: (args: { payload: IPostDataInEditingFlow }) => void;
  onPostOfferingBuyNowClick: IUseOfferingInEditPostModalProps["onPostOfferingBuyNowClick"];
  notifyError: (message: string) => void;
  customCheckPostContentIsNotEmpty?: (postContent: string) => boolean;
  onEditPostTypeMediaClick: IUseMediaInEditPostModalProps["onEditPostTypeMediaClick"];
}

export interface IUseEditPostModalReturn {
  // props used by the modal directly
  isOpen: IEditPostModalProps["isOpen"];
  onClose: IEditPostModalProps["onClose"];
  postContentDefaultValue: IEditPostModalProps["postContentDefaultValue"];
  postContentTextAreaRef: IEditPostModalProps["postContentTextAreaRef"];
  postMediaUploading: IEditPostModalProps["postMediaUploading"];
  postMediaIsAdded: IEditPostModalProps["postMediaIsAdded"];
  postImageProps: IEditPostModalProps["postImageProps"];
  postDocProps: IEditPostModalProps["postDocProps"];
  postVideoProps: IEditPostModalProps["postVideoProps"];
  postOfferingProps: IEditPostModalProps["postOfferingProps"];

  // props used by other components related to modal
  onSaveButtonClick: () => void;
  postTypeSelectorProps: {
    onPostTypeClick: IEditPostModalProps["postTypeSelectorProps"]["onPostTypeClick"];
    disablePostTypeClick: IEditPostModalProps["postTypeSelectorProps"]["disablePostTypeClick"];
  };
  imageSelectorProps: IUseImageInEditPostModalReturn["imageSelectorProps"];
  docSelectorProps: IUseDocInEditPostModalReturn["docSelectorProps"];
  videoSelectorProps: IUseVideoInEditPostModalReturn["videoSelectorProps"];
  offeringSelectorProps: IUseOfferingInEditPostModalReturn["offeringSelectorProps"];
}

export const useEditPostModal = ({
  isOpen,
  onClose,
  postData: postDataProp,
  disablePostTypeChange,
  disablePostTypeDataEdit,
  triggerPostTypeId: triggerPostTypeIdProp,
  onSaveButtonClick: onSaveButtonClickProp,
  onPostOfferingBuyNowClick,
  notifyError,
  customCheckPostContentIsNotEmpty: customCheckPostContentIsNotEmpty,
  onEditPostTypeMediaClick,
}: IUseEditPostModalProps): IUseEditPostModalReturn => {
  const postContentTextAreaRef = useRef<HTMLTextAreaElement | null>(null);

  /**
   * This value tells the triggered post type id.
   * If value is set, the respective post type child modal will open initially.
   * If the child modal is closed directly the base modal(edit post modal) will be closed as well.
   */
  const [triggerPostTypeId, setTriggerPostTypeId] = useState<
    number | undefined
  >(undefined);

  const onCloseSelectorWithoutSelecting = () => {
    if (
      [
        POST_TYPE_IDS_ENUM.IMAGE,
        POST_TYPE_IDS_ENUM.VIDEO,
        POST_TYPE_IDS_ENUM.DOCUMENT,
        POST_TYPE_IDS_ENUM.OFFERING,
      ].includes(triggerPostTypeId!)
    ) {
      onClose();
    }
  };
  const onAddPostTypeMediaSuccess = () => {
    // so that further editing of post type media does not trigger the onClose of the EditPostModal
    if (typeof triggerPostTypeId === "number") {
      setTriggerPostTypeId(undefined);
    }
  };

  const [postData, setPostData] = useState<IPostDataInEditingFlow>(
    postDataProp || POST_UPDATE_PAYLOAD_DEFAULT_VALUE,
  );

  const onSaveButtonClick = () => {
    let postContent = "";
    if (postContentTextAreaRef?.current) {
      postContent = postContentTextAreaRef.current.value || "";
    }

    if (
      postTypeId === POST_TYPE_IDS_ENUM.TEXT &&
      (customCheckPostContentIsNotEmpty
        ? !customCheckPostContentIsNotEmpty(postContent)
        : !postContent.trim())
    ) {
      notifyError("Post connot be empty!");
      return;
    }

    const payload: IPostDataInEditingFlow = {
      ...postData,
      post_body: postContent,
    };
    delete payload.listing_details; // we get listing details in the get api for rendering at front end... its not actually a part of the post data

    onSaveButtonClickProp({ payload });
  };

  const resetPostTypeToText = () => {
    setPostData({
      ...postData,
      post_type: DEFAULT_POST_TYPE_ID,
      post_listing_uuid: undefined,
      metadata: undefined,
    });
  };

  const onRemovePostTypeMedia = resetPostTypeToText;

  const { onAddPostTypeImageClick, postImageProps, imageSelectorProps } =
    useImageInEditPostModal({
      postData,
      setPostData,
      disablePostTypeDataEdit,
      onRemovePostTypeMedia,
      onCloseSelectorWithoutSelecting,
      onAddPostTypeMediaSuccess,
      onEditPostTypeMediaClick,
    });

  const { onAddPostTypeDocClick, postDocProps, docSelectorProps } =
    useDocInEditPostModal({
      postData,
      setPostData,
      disablePostTypeDataEdit,
      onRemovePostTypeMedia,
      onCloseSelectorWithoutSelecting,
      onAddPostTypeMediaSuccess,
      onEditPostTypeMediaClick,
    });

  const { onAddPostTypeVideoClick, postVideoProps, videoSelectorProps } =
    useVideoInEditPostModal({
      postData,
      setPostData,
      disablePostTypeDataEdit,
      onRemovePostTypeMedia,
      onCloseSelectorWithoutSelecting,
      onAddPostTypeMediaSuccess,
      onEditPostTypeMediaClick,
    });

  const {
    onAddPostTypeOfferingClick,
    postOfferingProps,
    offeringSelectorProps,
  } = useOfferingInEditPostModal({
    postData,
    setPostData,
    disablePostTypeDataEdit,
    onRemovePostTypeMedia,
    onCloseSelectorWithoutSelecting,
    onAddPostTypeMediaSuccess,
    onPostOfferingBuyNowClick,
    onEditPostTypeMediaClick,
  });

  const postTypeId = postData.post_type;
  const postMediaUploading =
    imageSelectorProps.imgUploading ||
    videoSelectorProps.videoUploading ||
    docSelectorProps.docUploading ||
    // obviously, uploading does not happen in case of offering
    false;
  let postMediaIsAdded: boolean | undefined = false;
  if (!postMediaUploading) {
    switch (postTypeId) {
      case POST_TYPE_IDS_ENUM.IMAGE:
        postMediaIsAdded = postImageProps.showImg;
        break;
      case POST_TYPE_IDS_ENUM.DOCUMENT:
        postMediaIsAdded = postDocProps.showDoc;
        break;
      case POST_TYPE_IDS_ENUM.VIDEO:
        postMediaIsAdded = postVideoProps.showVideo;
        break;
      case POST_TYPE_IDS_ENUM.OFFERING:
        postMediaIsAdded = postOfferingProps.showOffering;
        break;
      case POST_TYPE_IDS_ENUM.TEXT:
      default:
        break;
    }
  }

  const disablePostTypeClick =
    disablePostTypeChange || postMediaIsAdded || postMediaUploading;

  const onAddPostTypeMediaClick = (postTypeIdArg: number) => {
    if (disablePostTypeClick) return;
    switch (postTypeIdArg) {
      case POST_TYPE_IDS_ENUM.IMAGE:
        onAddPostTypeImageClick();
        break;
      case POST_TYPE_IDS_ENUM.DOCUMENT:
        onAddPostTypeDocClick();
        break;
      case POST_TYPE_IDS_ENUM.VIDEO:
        onAddPostTypeVideoClick();
        break;
      case POST_TYPE_IDS_ENUM.OFFERING:
        onAddPostTypeOfferingClick();
        break;
      case POST_TYPE_IDS_ENUM.TEXT:
      default:
        resetPostTypeToText();
        break;
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (typeof triggerPostTypeIdProp === "number") {
        setTriggerPostTypeId(triggerPostTypeIdProp);
        onAddPostTypeMediaClick(triggerPostTypeIdProp);
      }
    } else {
      setPostData(POST_UPDATE_PAYLOAD_DEFAULT_VALUE);
      setTriggerPostTypeId(undefined);
      if (postContentTextAreaRef.current) {
        postContentTextAreaRef.current.value = "";
      }
    }
  }, [isOpen]);

  return {
    // props used by the modal directly
    isOpen,
    onClose,
    postContentDefaultValue: postData.post_body,
    postContentTextAreaRef,
    postMediaUploading,
    postMediaIsAdded,
    postImageProps,
    postDocProps,
    postVideoProps,
    postOfferingProps,

    // props used by other components related to modal
    onSaveButtonClick,
    postTypeSelectorProps: {
      onPostTypeClick: onAddPostTypeMediaClick,
      disablePostTypeClick,
    },
    imageSelectorProps,
    docSelectorProps,
    videoSelectorProps,
    offeringSelectorProps,
  };
};
