import { useState } from "react";
import { POST_TYPE_IDS_ENUM } from "../../../constants/BrandedCommunity/Post";
import { IUseMediaInEditPostModalProps } from "./useMediaInEditPostModal.types";
import { IEditPostModalProps } from "../../../components/BrandedCommunity/EditPostModal/EditPostModal.types";

export interface IDocSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseWithoutSelecting: () => void;
  onChangeDoc: (props: { docUrl: string; docName: string }) => void;
  docUploading: boolean;
  setDocUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IUseDocInEditPostModalReturn {
  onAddPostTypeDocClick: () => void;
  postDocProps: IEditPostModalProps["postDocProps"];
  docSelectorProps: IDocSelectorProps;
}

export const useDocInEditPostModal = ({
  postData,
  setPostData,
  disablePostTypeDataEdit,
  onRemovePostTypeMedia,
  onCloseSelectorWithoutSelecting,
}: IUseMediaInEditPostModalProps): IUseDocInEditPostModalReturn => {
  const [docSelectorIsOpen, setDocSelectorIsOpen] = useState(false);
  const [docUploading, setDocUploading] = useState(false);
  const onAddPostTypeDocClick = () => {
    setDocSelectorIsOpen(true);
  };
  const onCloseDocSelector = () => {
    setDocSelectorIsOpen(false);
  };

  const docUrl = postData?.metadata?.post_doc_url;
  const docName = postData?.metadata?.post_doc_name;
  const onChangeDoc: IUseDocInEditPostModalReturn["docSelectorProps"]["onChangeDoc"] =
    ({ docUrl: newDocUrl, docName: newDocName }) => {
      setPostData({
        ...postData,
        post_type: POST_TYPE_IDS_ENUM.DOCUMENT,
        metadata: {
          ...postData?.metadata,
          post_doc_url: newDocUrl,
          post_doc_name: newDocName,
        },
      });
      onCloseDocSelector();
    };

  const postTypeDocIsAdded =
    !!docUrl && postData.post_type === POST_TYPE_IDS_ENUM.DOCUMENT;

  const onClose = onCloseDocSelector;
  const onCloseWithoutSelecting = () => {
    onCloseSelectorWithoutSelecting();
    onClose();
  };

  return {
    onAddPostTypeDocClick,

    postDocProps: {
      showDoc: postTypeDocIsAdded,
      docUrl,
      docName,
      isRemovable: !disablePostTypeDataEdit,
      onRemove: onRemovePostTypeMedia,
    },

    docSelectorProps: {
      isOpen: docSelectorIsOpen,
      onClose,
      onCloseWithoutSelecting,
      onChangeDoc,
      docUploading,
      setDocUploading,
    },
  };
};
