import { IEditPostModalProps } from "../../../components/BrandedCommunity/EditPostModal/EditPostModal.types";
import { IUseMediaInEditPostModalProps } from "./useMediaInEditPostModal.types";
import { POST_TYPE_IDS_ENUM } from "../../../constants/BrandedCommunity/Post";
import { useState } from "react";

export interface IVideoSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseWithoutSelecting: () => void;
  onChangeVideoUrl: (newVideoUrl: string) => void;
  videoUploading: boolean;
  setVideoUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

export type IUseVideoInEditPostModalReturn = {
  onAddPostTypeVideoClick: () => void;
  postVideoProps: IEditPostModalProps["postVideoProps"];
  videoSelectorProps: IVideoSelectorProps;
};

export const useVideoInEditPostModal = ({
  postData,
  setPostData,
  disablePostTypeDataEdit,
  onRemovePostTypeMedia,
  onCloseSelectorWithoutSelecting,
  onAddPostTypeMediaSuccess,
}: IUseMediaInEditPostModalProps): IUseVideoInEditPostModalReturn => {
  const [videoSelectorIsOpen, setVideoSelectorIsOpen] = useState(false);
  const [videoUploading, setVideoUploading] = useState(false);
  const onAddPostTypeVideoClick = () => {
    setVideoSelectorIsOpen(true);
  };
  const onCloseVideoSelector = () => {
    setVideoSelectorIsOpen(false);
  };

  const videoUrl = postData?.metadata?.post_video_url;
  const onChangeVideoUrl = (newVideoUrl: string) => {
    setPostData({
      ...postData,
      post_type: POST_TYPE_IDS_ENUM.VIDEO,
      metadata: {
        ...postData?.metadata,
        post_video_url: newVideoUrl,
      },
    });
    onAddPostTypeMediaSuccess();
    onCloseVideoSelector();
  };

  const postTypeVideoIsAdded =
    !!videoUrl && postData.post_type === POST_TYPE_IDS_ENUM.VIDEO;

  const onClose = onCloseVideoSelector;
  const onCloseWithoutSelecting = () => {
    onCloseSelectorWithoutSelecting();
    onClose();
  };

  return {
    onAddPostTypeVideoClick,

    postVideoProps: {
      showVideo: postTypeVideoIsAdded,
      videoUrl,
      isRemovable: !disablePostTypeDataEdit,
      onRemove: onRemovePostTypeMedia,
    },

    videoSelectorProps: {
      isOpen: videoSelectorIsOpen,
      onClose,
      onCloseWithoutSelecting,
      onChangeVideoUrl,
      videoUploading,
      setVideoUploading,
    },
  };
};
