import { IPostOfferingProps } from "../../../components/BrandedCommunity/PostOffering/PostOffering.types";

export const evaluateHidePostOffering = ({
  showOffering,
  offering,
}: {
  showOffering: IPostOfferingProps["showOffering"];
  offering: IPostOfferingProps["offering"];
}) => {
  if (!showOffering || !offering) return true;
};
