import { useState } from "react";
import {
  IUseLikedByMembersListModalArgs,
  IUseLikedByMembersListModalReturn,
} from "./useLikedByUsersListModal.types";
import { ILikedByMembersDataList } from "../../../components/BrandedCommunity/LikedByMembersListModal/LikedByMembersListModal.types";

export const useLikedByMembersListModal = ({
  userAvatarPreference,
  callGetLikedByMembersListApi,
}: IUseLikedByMembersListModalArgs): IUseLikedByMembersListModalReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<ILikedByMembersDataList>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const onCallGetLikedByMembersListApi = ({ page: pageArg }) => {
    if (isLoading) return;
    setIsLoading(true);
    callGetLikedByMembersListApi({ page: pageArg })
      .then((response) => {
        const responseData = response?.data || {};
        if (page === 1) {
          setData(responseData.members);
        } else {
          setData([...data, ...responseData.members]);
        }
        setTotal(responseData.total || 0);
        setPage(pageArg);
      })
      .finally(() => setIsLoading(false));
  };

  const open = () => {
    setIsOpen(true);
    onCallGetLikedByMembersListApi({ page: 1 });
  };

  const onLoadMore = () => {
    if (data.length < total) onCallGetLikedByMembersListApi({ page: page + 1 });
  };

  /**
   * TODO(DEV) - This method of infinite scroll wont work in case screen size is too big and
   *  number of element doesnt overflow the list to have scroll functionality.
   * For now since page_size is 50 the case wont come but in case page_size becomes 5 and screen size is big
   * we wont have a scroll it wont be able to fetch more items.
   */
  const loadMoreOnScroll: React.DOMAttributes<HTMLElement>["onScroll"] = (
    e,
  ) => {
    if (!e.target) return;
    const { scrollTop, scrollHeight, clientHeight } = e.target as Element;
    if (scrollTop + clientHeight >= scrollHeight) onLoadMore();
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    data,
    total,
    open,
    onClose,
    loadMoreOnScroll,
    userAvatarPreference,
  };
};
