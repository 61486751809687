import { useEffect, useState } from "react";
import { checkIsBrowser } from "./windowObj";
import {
  MOBILE_SCREEN_WIDTH,
  TABLET_SCREEN_WIDTH,
} from "../../constants/common/screen";
import { checkIsDefined } from "../../features/Common/modules/DataTypes/utils/nanNullUndef";

const SERVER_SIDE_DIMENSION = -1;

export const useScreenSize = (): { width: number; height: number } => {
  const getWidth = () =>
    checkIsBrowser() ? window.innerWidth : SERVER_SIDE_DIMENSION;
  const getHeight = () =>
    checkIsBrowser() ? window.innerHeight : SERVER_SIDE_DIMENSION;

  const [width, setWidth] = useState(getWidth());
  const [height, setHeight] = useState(getHeight());

  const updateDimensions = () => {
    setWidth(getWidth());
    setHeight(getHeight());
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  return { width, height };
};

export const useIsDesktop = (options?: { serverSideValue?: boolean }) => {
  const { width } = useScreenSize();

  const serverSideValue = options?.serverSideValue;
  if (checkIsDefined(serverSideValue) && width === SERVER_SIDE_DIMENSION) {
    return serverSideValue as boolean;
  }

  return width > MOBILE_SCREEN_WIDTH;
};
export const useIsTablet = (options?: { serverSideValue?: boolean }) => {
  const { width } = useScreenSize();

  const serverSideValue = options?.serverSideValue;
  if (checkIsDefined(serverSideValue) && width === SERVER_SIDE_DIMENSION) {
    return serverSideValue as boolean;
  }

  return width > TABLET_SCREEN_WIDTH;
};
