import { IEditPostModalProps } from "../../../components/BrandedCommunity/EditPostModal/EditPostModal.types";
import { IUseMediaInEditPostModalProps } from "./useMediaInEditPostModal.types";
import { POST_TYPE_IDS_ENUM } from "../../../constants/BrandedCommunity/Post";
import { useState } from "react";
import { IUseEditPostModalReturn } from "./useEditPostModal";

export type IUseOfferingInEditPostModalProps = IUseMediaInEditPostModalProps & {
  onPostOfferingBuyNowClick: (args: {
    listingUuid: string;
    isCommunityOffering?: boolean;
  }) => void;
};

export interface IOfferingSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseWithoutSelecting: () => void;
  offering: IEditPostModalProps["postOfferingProps"]["offering"];
  onChangeOffering: (props: {
    newOffering: IEditPostModalProps["postOfferingProps"]["offering"];
    domesticLtoExists?: boolean;
    intlLtoExists?: boolean;
  }) => void;
}

export interface IUseOfferingInEditPostModalReturn {
  onAddPostTypeOfferingClick: () => void;
  postOfferingProps: IUseEditPostModalReturn["postOfferingProps"];
  offeringSelectorProps: IOfferingSelectorProps;
}

export const useOfferingInEditPostModal = ({
  postData,
  setPostData,
  disablePostTypeDataEdit,
  onRemovePostTypeMedia,
  onCloseSelectorWithoutSelecting,
  onAddPostTypeMediaSuccess,
  onPostOfferingBuyNowClick,
}: IUseOfferingInEditPostModalProps): IUseOfferingInEditPostModalReturn => {
  const [offeringSelectorIsOpen, setOfferingSelectorIsOpen] = useState(false);
  const [offeringMetadata, setOfferingMetadata] = useState<
    IUseOfferingInEditPostModalReturn["postOfferingProps"]["offeringMetadata"]
  >({});
  const onAddPostTypeOfferingClick = () => {
    setOfferingSelectorIsOpen(true);
  };
  const onCloseOfferingSelector = () => {
    setOfferingSelectorIsOpen(false);
  };

  const offering = postData?.listing_details;
  const onChangeOffering: IUseOfferingInEditPostModalReturn["offeringSelectorProps"]["onChangeOffering"] =
    ({
      newOffering,
      domesticLtoExists: domesticLtoExistsArg,
      intlLtoExists: intlLtoExistsArg,
    }) => {
      setPostData({
        ...postData,
        post_listing_uuid: newOffering!.uuid,
        post_type: POST_TYPE_IDS_ENUM.OFFERING,
        listing_details: newOffering,
      });
      setOfferingMetadata({
        domesticLtoExists: domesticLtoExistsArg,
        intlLtoExists: intlLtoExistsArg,
      });
      onAddPostTypeMediaSuccess();
      onCloseOfferingSelector();
    };

  const postTypeOfferingIsAdded =
    offering && postData.post_type === POST_TYPE_IDS_ENUM.OFFERING;

  const onClose = onCloseOfferingSelector;
  const onCloseWithoutSelecting = () => {
    onCloseSelectorWithoutSelecting();
    onClose();
  };

  const isCommunityOffering =
    postData?.is_community_offering || offering?.is_community_offering;

  return {
    onAddPostTypeOfferingClick,

    postOfferingProps: {
      showOffering: postTypeOfferingIsAdded,
      offering,
      isCommunityOffering,
      offeringMetadata,
      isEditable: !disablePostTypeDataEdit,
      onEdit: onAddPostTypeOfferingClick,
      isRemovable: !disablePostTypeDataEdit,
      onRemove: onRemovePostTypeMedia,
      onBuyNowClick: () =>
        onPostOfferingBuyNowClick({
          listingUuid: offering?.uuid || "",
          isCommunityOffering,
        }),
    },

    offeringSelectorProps: {
      isOpen: offeringSelectorIsOpen,
      onClose,
      onCloseWithoutSelecting,
      offering,
      onChangeOffering,
    },
  };
};
