import { useState } from "react";
import { checkIsArrayEmpty } from "../../common/dataTypes/array";
import { IPostDataInFeed } from "../../../components/BrandedCommunity/Post/Post.types";
import {
  IGetPostsListApiResponse,
  IPostsListPageWiseData,
  IUsePostsListContainerProps,
  IUsePostsListContainerReturn,
} from "./usePostsListContainer.types";
import {
  POSTS_LIST_PAGE_DEFAULT_DATA,
  POSTS_TABS_KEYS,
} from "../../../constants/BrandedCommunity/PostsListContainer";
import { checkIsPromise } from "../../../features/Common/modules/DataTypes/utils/promises";
import { checkIsFunction } from "../../common/dataTypes/function";

const default_current_tab = POSTS_TABS_KEYS.FEED;
const default_posts_list_last_page = 0;
const default_posts_list_init_page = 1;
const default_posts_list_page_wise_data = {};

export const usePostsListContainer = ({
  lastViewTime,
  callGetPostsListApi,
  callGetPinnedPostsListApi,
  callFeedViewActionApi,
  getIsPinnedPostsListVisited,
  setIsPinnedPostsListVisited,
}: IUsePostsListContainerProps): IUsePostsListContainerReturn => {
  const [currentTab, setCurrentTab] = useState(default_current_tab);

  /**
   * represents last page with correct post items:
   * - none of the items in pages till now is deleted or pinned after load
   * - none of the items in pinned posts list is unpinned after load
   */
  const [feedPostsListLastPage, setFeedPostsListLastPage] = useState(
    default_posts_list_last_page,
  );
  const [fetchingFeedPostsListLastPage, setFetchingFeedPostsListLastPage] =
    useState(false);
  const [feedPostsListPageWiseData, setFeedPostsListPageWiseData] =
    useState<IPostsListPageWiseData>(default_posts_list_page_wise_data);
  /**
   * represents last page with correct pinned post items:
   * - none of the items in pages till now is deleted or unpinned after load
   * - none of the items in normal posts list is pinned after load
   */
  const [pinnedPostsListLastPage, setPinnedPostsListLastPage] = useState(
    default_posts_list_last_page,
  );
  const [fetchingPinnedPostsListLastPage, setFetchingPinnedPostsListLastPage] =
    useState(false);

  const [pinnedPostsListPageWiseData, setPinnedPostsListPageWiseData] =
    useState<IPostsListPageWiseData>(default_posts_list_page_wise_data);

  const fetchFeedPostsListAndUpdateLastPage = ({ page }: { page: number }) => {
    if (page === feedPostsListLastPage + 1) {
      setFetchingFeedPostsListLastPage(true);
    }
    return callGetPostsListApi({ page })
      .then((response) => {
        const newFeedPostsListPageWiseData = {
          ...feedPostsListPageWiseData,
        };
        newFeedPostsListPageWiseData[page] = {
          ...getPageDataFromResponse(response),
        };
        setFeedPostsListPageWiseData(newFeedPostsListPageWiseData);
        setFeedPostsListLastPage(page);
      })
      .catch(() => {})
      .finally(() => {
        // set fetchingFeedPostsListLastPage as false as it can be set true from other places as well
        setFetchingFeedPostsListLastPage(false);
      });
  };

  const fetchPinnedPostsListAndUpdateLastPage = ({
    page,
    calledDuringInit,
  }: {
    page: number;
    calledDuringInit?: boolean;
  }) => {
    if (page === pinnedPostsListLastPage + 1) {
      setFetchingPinnedPostsListLastPage(true);
    }
    return callGetPinnedPostsListApi({ page })
      .then((response) => {
        const newPinnedPostsListPageWiseData = {
          ...pinnedPostsListPageWiseData,
        };
        newPinnedPostsListPageWiseData[page] = {
          ...getPageDataFromResponse(response),
        };
        setPinnedPostsListPageWiseData(newPinnedPostsListPageWiseData);
        setPinnedPostsListLastPage(page);

        /**
         * if
         * - we are on pinned tab
         * - and only one post exists in that tab
         * - and that post gets un-pinned or deleted
         * we should switch tab to feeds
         */
        if (
          page === default_posts_list_init_page &&
          checkIsArrayEmpty(getPageDataFromResponse(response).posts) &&
          currentTab === POSTS_TABS_KEYS.PINNED_POSTS
        ) {
          setCurrentTab(POSTS_TABS_KEYS.FEED);
        } else if (
          // if: user is visiting the feed/channel for the first time
          //     AND
          //     pinned posts exist
          // then: we need to open pinned post tab
          calledDuringInit &&
          page === default_posts_list_init_page &&
          !checkIsArrayEmpty(getPageDataFromResponse(response).posts)
        ) {
          if (checkIsPromise(getIsPinnedPostsListVisited)) {
            (getIsPinnedPostsListVisited as Promise<boolean>)
              .then((response) => {
                if (!response) setCurrentTab(POSTS_TABS_KEYS.PINNED_POSTS);
              })
              .catch(() => {});
          } else if (
            checkIsFunction(getIsPinnedPostsListVisited) &&
            !(getIsPinnedPostsListVisited as () => boolean)()
          ) {
            setCurrentTab(POSTS_TABS_KEYS.PINNED_POSTS);
          } else {
            setCurrentTab(default_current_tab);
          }

          setIsPinnedPostsListVisited?.();
        }
      })
      .catch(() => {})
      .finally(() => {
        // set fetchingPinnedPostsListLastPage as false as it can be set true from other places as well
        setFetchingPinnedPostsListLastPage(false);
      });
  };

  const resetToInitialState = ({
    calledDuringInit,
  }: { calledDuringInit?: boolean } = {}) => {
    setFeedPostsListLastPage(default_posts_list_last_page);
    setFetchingFeedPostsListLastPage(true);
    setPinnedPostsListLastPage(default_posts_list_last_page);
    setFetchingPinnedPostsListLastPage(true);
    fetchFeedPostsListAndUpdateLastPage({ page: default_posts_list_init_page });
    fetchPinnedPostsListAndUpdateLastPage({
      page: default_posts_list_init_page,
      calledDuringInit,
    });
  };

  /**
   * update, delete, pin toggle method's base functions
   */
  const filterOutPostFromPageWiseData = ({
    pageWiseData,
    postData,
    page,
  }: {
    pageWiseData: IPostsListPageWiseData;
    postData: IPostDataInFeed;
    page: number;
  }) => {
    const newPageWiseData = { ...pageWiseData };
    newPageWiseData[page] = {
      ...pageWiseData[page],
      posts: pageWiseData[page].posts.filter(
        (i) => i.post_uuid !== postData.post_uuid,
      ),
    };
    return newPageWiseData;
  };

  const updatePostInPageWiseData = ({
    pageWiseData,
    postData,
    page,
  }: {
    pageWiseData: IPostsListPageWiseData;
    postData: IPostDataInFeed;
    page: number;
  }) => {
    const newPageWiseData = { ...pageWiseData };
    newPageWiseData[page] = {
      ...pageWiseData[page],
      posts: pageWiseData[page].posts.map((i) => ({
        ...(i.post_uuid === postData.post_uuid ? postData : i),
      })),
    };
    return newPageWiseData;
  };

  /**
   * Delete methods
   */
  const onDeleteSuccessOfFeedPost = ({
    postData,
    page,
  }: {
    postData: IPostDataInFeed;
    page: number;
  }) => {
    const newFeedPostsListPageWiseData = filterOutPostFromPageWiseData({
      pageWiseData: feedPostsListPageWiseData,
      postData,
      page,
    });

    setFeedPostsListPageWiseData(newFeedPostsListPageWiseData);
    fetchFeedPostsListAndUpdateLastPage({ page });

    if (postData.is_pinned) {
      // if the post was a pinned post, the data in pinned posts tab would be corrupt
      fetchPinnedPostsListAndUpdateLastPage({
        page: default_posts_list_init_page,
      });
    }
  };

  const onDeleteSuccessOfPinnedPost = ({
    postData,
    page,
  }: {
    postData: IPostDataInFeed;
    page: number;
  }) => {
    const newPinnedPostsListPageWiseData = filterOutPostFromPageWiseData({
      pageWiseData: pinnedPostsListPageWiseData,
      postData,
      page,
    });
    setPinnedPostsListPageWiseData(newPinnedPostsListPageWiseData);
    fetchPinnedPostsListAndUpdateLastPage({ page });

    // if post is deleted in pinned tab, the data in feed tab would be corrupt
    fetchFeedPostsListAndUpdateLastPage({ page: default_posts_list_init_page });
  };

  /**
   * pin toggle methods
   */
  const onPinToggleSuccessOfFeedPost = ({
    postData,
    page,
  }: {
    postData: IPostDataInFeed;
    page: number;
  }) => {
    // Pinning state is handled using local state by the post component.
    // But if the user switches tab, the state will be lost,
    // as the component will be un mounted and remounted.
    // And the state will be lost
    const newFeedPostsListPageWiseData = updatePostInPageWiseData({
      pageWiseData: feedPostsListPageWiseData,
      postData,
      page,
    });
    setFeedPostsListPageWiseData(newFeedPostsListPageWiseData);
    // dont need to update last page and refetch data in feed as local state is updated correctly

    // the data in pinned posts tab would be corrupt
    fetchPinnedPostsListAndUpdateLastPage({
      page: default_posts_list_init_page,
    });
  };

  const onPinToggleSuccessOfPinnedPost = ({
    postData,
    page,
  }: {
    postData: IPostDataInFeed;
    page: number;
  }) => {
    // Pinning state is handled using local state by the post component.
    // But if the user switches tab, the state will be lost,
    // as the component will be un mounted and remounted.
    // And the state will be lost
    const newPinnedPostsListPageWiseData = updatePostInPageWiseData({
      pageWiseData: pinnedPostsListPageWiseData,
      postData,
      page,
    });
    setPinnedPostsListPageWiseData(newPinnedPostsListPageWiseData);
    fetchPinnedPostsListAndUpdateLastPage({ page });

    // the data in feed tab would be corrupt
    fetchFeedPostsListAndUpdateLastPage({ page: default_posts_list_init_page });
  };

  /**
   * update methods - for edit post content, like / dislike
   */
  const onUpdateSuccessOfFeedPost = ({
    postData,
    page,
  }: {
    postData: IPostDataInFeed;
    page: number;
  }) => {
    const newFeedPostsListPageWiseData = updatePostInPageWiseData({
      pageWiseData: feedPostsListPageWiseData,
      postData,
      page,
    });
    setFeedPostsListPageWiseData(newFeedPostsListPageWiseData);
    // dont need to update last page and refetch data in feed as local state is updated correctly

    if (postData.is_pinned) {
      // if the post was a pinned post, the data in pinned posts tab would be corrupt
      fetchPinnedPostsListAndUpdateLastPage({
        page: default_posts_list_init_page,
      });
    }
  };

  const onUpdateSuccessOfPinnedPost = ({
    postData,
    page,
  }: {
    postData: IPostDataInFeed;
    page: number;
  }) => {
    const newPinnedPostsListPageWiseData = updatePostInPageWiseData({
      pageWiseData: pinnedPostsListPageWiseData,
      postData,
      page,
    });
    setPinnedPostsListPageWiseData(newPinnedPostsListPageWiseData);
    // dont need to update last page and refetch data in pinned posts as local state is updated correctly

    // the data in feed tab would be corrupt, need tyo refetch it
    fetchFeedPostsListAndUpdateLastPage({ page: default_posts_list_init_page });
  };

  const hasFeedPosts =
    feedPostsListPageWiseData[feedPostsListLastPage]?.total > 0;
  const hasPinnedPosts =
    pinnedPostsListPageWiseData[pinnedPostsListLastPage]?.total > 0;

  let selectedTabPostsPageWiseData = feedPostsListPageWiseData;
  let selectedTabPostsLastPage = feedPostsListLastPage;
  let fetchingSelectedTabPostsLastPage = fetchingFeedPostsListLastPage;
  let fetchSelectedTabPostsAndUpdateLastPage =
    fetchFeedPostsListAndUpdateLastPage;
  let onDeleteSuccessOfPost = onDeleteSuccessOfFeedPost;
  let onPinToggleSuccessOfPost = onPinToggleSuccessOfFeedPost;
  let onUpdateSuccessOfPost = onUpdateSuccessOfFeedPost;
  if (hasPinnedPosts && currentTab === POSTS_TABS_KEYS.PINNED_POSTS) {
    selectedTabPostsPageWiseData = pinnedPostsListPageWiseData;
    selectedTabPostsLastPage = pinnedPostsListLastPage;
    fetchingSelectedTabPostsLastPage = fetchingPinnedPostsListLastPage;
    fetchSelectedTabPostsAndUpdateLastPage =
      fetchPinnedPostsListAndUpdateLastPage;
    onDeleteSuccessOfPost = onDeleteSuccessOfPinnedPost;
    onPinToggleSuccessOfPost = onPinToggleSuccessOfPinnedPost;
    onUpdateSuccessOfPost = onUpdateSuccessOfPinnedPost;
  }

  /**
   * loadMore logic
   */
  const loadMorePostsOfSelectedTab = () => {
    if (fetchingSelectedTabPostsLastPage) return;
    if (
      selectedTabPostsPageWiseData[selectedTabPostsLastPage]?.total_pages >
      selectedTabPostsLastPage
    ) {
      fetchSelectedTabPostsAndUpdateLastPage({
        page: selectedTabPostsLastPage + 1,
      });
    } else {
      // at last page, do nothing
    }
  };

  const listOfPagesForSelectedTabPosts = Array.from(
    { length: selectedTabPostsLastPage },
    (_, i) => i + 1,
  );

  const onInit = () => {
    resetToInitialState({ calledDuringInit: true });
    callFeedViewActionApi();
  };

  const onCreatePostSuccess = () => {
    setCurrentTab(default_current_tab);
    resetToInitialState(); // New post come at the beginning of the list. We should refetch the first page.
    callFeedViewActionApi();
  };

  return {
    onInit,
    lastViewTime,
    hasFeedPosts,
    hasPinnedPosts,
    currentTab,
    setCurrentTab,
    listOfPagesForSelectedTabPosts,
    selectedTabPostsPageWiseData,
    onPinToggleSuccessOfPost,
    onUpdateSuccessOfPost,
    onDeleteSuccessOfPost,
    fetchingSelectedTabPostsLastPage,
    onCreatePostSuccess,
    loadMorePostsOfSelectedTab,
  };
};

/**
 * fetch page methods
 */
const getPageDataFromResponse = (response: IGetPostsListApiResponse) => {
  const responseData = (response || {}).data || {};
  return {
    posts: responseData.posts ?? POSTS_LIST_PAGE_DEFAULT_DATA.posts,
    total_pages:
      responseData.total_pages ?? POSTS_LIST_PAGE_DEFAULT_DATA.total_pages,
    total: responseData.total ?? POSTS_LIST_PAGE_DEFAULT_DATA.total,
  };
};
