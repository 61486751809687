import { EXLY_WEBAPP_URL_SEARCH_PARAMS } from "../../constants/exly-webapp/url";
import { appendURLSearchParams } from "../common/url";

/**
 *
 * @param protocol - use window.localtion.protocol
 * @param subDomain - creator subdomain
 * @param domain - changes on the basis of the env
 * @param listingUuid - uuid of the listing we have clicked on
 * @param isCommunityOffering - whether it is a bonus offering in community or not
 * @param brandedCommunityUuid - branded community uuid
 */
export const onOfferingClickInBrandedCommunity = ({
  protocol = "https:",
  subDomain,
  domain,
  listingUuid,
  isCommunityOffering,
  brandedCommunityUuid,
}: {
  protocol?: "https:" | "http:";
  subDomain: string;
  domain: string;
  listingUuid: string;
  isCommunityOffering?: boolean;
  brandedCommunityUuid: string;
}): void => {
  const baseUrl = `${protocol}//${subDomain}.${domain}/${listingUuid}`;
  const urlToPush = isCommunityOffering
    ? appendURLSearchParams(baseUrl, {
        [EXLY_WEBAPP_URL_SEARCH_PARAMS.COMMUNITY_ID]: brandedCommunityUuid,
      })
    : baseUrl;

  window.open(urlToPush, "_blank");
};
