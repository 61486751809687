import { IEditPostModalProps } from "../../../components/BrandedCommunity/EditPostModal/EditPostModal.types";
import { IUseMediaInEditPostModalProps } from "./useMediaInEditPostModal.types";
import { POST_TYPE_IDS_ENUM } from "../../../constants/BrandedCommunity/Post";
import { useState } from "react";

export interface IImageSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseWithoutSelecting: () => void;
  onChangeImgSrc: (newImgSrc: string) => void;
  imgUploading: boolean;
  setImgUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IUseImageInEditPostModalReturn {
  onAddPostTypeImageClick: () => void;
  postImageProps: IEditPostModalProps["postImageProps"];
  imageSelectorProps: IImageSelectorProps;
}

export const useImageInEditPostModal = ({
  postData,
  setPostData,
  disablePostTypeDataEdit,
  onRemovePostTypeMedia,
  onCloseSelectorWithoutSelecting,
  onAddPostTypeMediaSuccess,
  onEditPostTypeMediaClick,
}: IUseMediaInEditPostModalProps): IUseImageInEditPostModalReturn => {
  const [imageSelectorIsOpen, setImageSelectorIsOpen] = useState(false);
  const [imgUploading, setImgUploading] = useState(false);
  const onAddPostTypeImageClick = () => {
    setImageSelectorIsOpen(true);
  };
  const onEditPostTypeImageClick = () => {
    onAddPostTypeImageClick();
    onEditPostTypeMediaClick?.(POST_TYPE_IDS_ENUM.IMAGE);
  };
  const onCloseImageSelector = () => {
    setImageSelectorIsOpen(false);
  };

  const imgSrc = postData?.metadata?.post_image_url;
  const onChangeImgSrc = (newImgSrc: string) => {
    setPostData({
      ...postData,
      post_type: POST_TYPE_IDS_ENUM.IMAGE,
      metadata: {
        ...postData?.metadata,
        post_image_url: newImgSrc,
      },
    });
    onAddPostTypeMediaSuccess();
    onCloseImageSelector();
  };

  const postTypeImageIsAdded =
    !!imgSrc && postData.post_type === POST_TYPE_IDS_ENUM.IMAGE;

  const onClose = onCloseImageSelector;
  const onCloseWithoutSelecting = () => {
    onCloseSelectorWithoutSelecting();
    onClose();
  };

  return {
    onAddPostTypeImageClick,

    postImageProps: {
      showImg: postTypeImageIsAdded,
      imgSrc,
      isEditable: !disablePostTypeDataEdit,
      onEdit: onEditPostTypeImageClick,
      isRemovable: !disablePostTypeDataEdit,
      onRemove: onRemovePostTypeMedia,
    },

    imageSelectorProps: {
      isOpen: imageSelectorIsOpen,
      onClose,
      onCloseWithoutSelecting,
      onChangeImgSrc,
      imgUploading,
      setImgUploading,
    },
  };
};
