import { format } from "date-fns";

const date_time_formats = {
  time: "hh:mm a",
  date_year_time: "MMMM dd, yyyy, hh:mm a",
  date_time: "MMMM dd, hh:mm a",
  date_year: "MMMM dd, yyyy",
  date_no_year: "MMMM dd",
};

export const formatDateTime = (
  date: Date,
  options?: { showTime?: boolean; showYear?: boolean },
) => {
  const time = options?.showTime ?? false;
  const year = options?.showYear ?? false;
  if (time && year) {
    return format(date, date_time_formats.date_year_time);
  } else if (time) {
    return format(date, date_time_formats.date_time);
  } else if (year) {
    return format(date, date_time_formats.date_year);
  } else {
    return format(date, date_time_formats.date_no_year);
  }
};

export const formatTime = (date: Date) => format(date, date_time_formats.time);
