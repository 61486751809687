import { IPostDocProps } from "../../../components/BrandedCommunity/PostDoc/PostDoc.types";

export const evaluateHidePostDoc = ({
  showDoc,
  docUrl,
}: {
  showDoc: IPostDocProps["showDoc"];
  docUrl: IPostDocProps["docUrl"];
}) => {
  if (!showDoc || !docUrl) return true;
};
