import { css } from "@emotion/css";
import type { CSSInterpolation } from "@emotion/css";

type ICssClass = string | boolean | null | undefined | number;

const isVlaidClass = (s: ICssClass) => s && typeof s === "string";

const filterValidClasses = (classes: Array<ICssClass>): Array<string> =>
  classes.filter(isVlaidClass) as Array<string>;

export const classNames = (...classes: Array<ICssClass>) =>
  filterValidClasses(classes).join(" ").trim();

const EXLY_REACT_LIB_CLASSNAME_PREFIX = "--ERL"; // keep it same as the `prefix` in `postcss.config.js`

export const getStylesObjFromCss = ({
  styles,
  componentName,
}: {
  styles: Record<string | symbol, CSSInterpolation>;
  componentName: string;
}): Record<string | symbol, string> => {
  const classNamesObj = {};
  Object.keys(styles).forEach((k) => {
    classNamesObj[k] = css({
      ...(styles[k] as Record<string | symbol, unknown>),
      label: `${k}${
        componentName ? "--" + componentName : ""
      }${EXLY_REACT_LIB_CLASSNAME_PREFIX}`,
    });
  });
  return classNamesObj;
};
